import React from "react"
import { Columns, Section } from "./Containers"
import { Image } from "./Core"
import "./Affiliations.scss"

const Affiliations = ({ heading, logos, className }) => {
  return (
    <Section className={className}>
      <Columns sideColumnsSize={2}>
        <div className="column">
          <div className="affiliations">
            <p>{heading}</p>
            <div className="affiliations-logos px-40--mobile">
              {logos.map(item => {
                if (item.link) {
                  return (
                    <a
                      key={item.logo}
                      href={item.link}
                      target="_blank"
                      title={`Visit ${item.link} to learn more`}>
                      <Image publicId={item.logo} />
                    </a>
                  )
                }
                return (
                  <div key={item.logo}>
                    <Image publicId={item.logo} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default Affiliations
