import React from "react"

import { Section, Columns } from "../../components/Containers"
import { Text, Image } from "../../components/Core"
import { ButtonGroupMap } from "../../components/Button"

function FirstTime(props) {
  const { useImages, image } = props
  return (
    <Section
      colorBack={props.colorBack}
      // zeroTop
      zeroBottomMobile
      className={`first-time`}>
      <Columns sideColumnsSize={useImages ? 2 : 4}>
        <div className="column">
          <div className={`${useImages ? "first-time__grid" : ""}`}>
            {useImages && image && (
              <div className="first-time__image">
                <Image publicId={image} />
              </div>
            )}
            <div className="first-time__card">
              <Text
                as="h3"
                className={`${
                  !useImages ? "has-text-centered" : "has-text-centered-touch"
                } mt-0`}
                style={{ marginBottom: "16px" }}
                text={props.post.firstTimePatient.heading}
              />
              <Text
                as="p"
                className={`${
                  !useImages ? "has-text-centered" : "has-text-centered-touch"
                }`}
                text={props.post.firstTimePatient.text}
              />
              <ButtonGroupMap
                buttons={props.post.firstTimePatient.buttons}
                isCentered={!useImages}
                isCenteredMobile={useImages}
                noReverse={props.noReverse}
              />
            </div>
          </div>
        </div>
      </Columns>
    </Section>
  )
}

export default FirstTime
