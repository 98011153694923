import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FixedFacewallHomepage from "./index/FixedFacewallHomepage"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import ImageText from "./index/ImageText"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import LearnMoreCards from "./index/LearnMoreCards"
// import ImageTexts from "../components/ImageTexts"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Image, Text } from "../components/Core"
import { Columns, Section } from "../components/Containers"
import TheaterVideo from "../components/Theater/TheaterVideo"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import { ProgramToast } from "../components/program-toast"
import { Button } from "../components/Button"
import Affiliations from "../components/Affiliations"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    const hasReviews = this.props.data.hasReviews.nodes[0].reviews
    let allReviews = this.props.data.allReviews.nodes

    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <Layout
        className={`main-homepage ${language}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <div className="static-container" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              language={language}
            />
          )}

          {language == "en" && (
            <>
              {hasReviews && (
                <TestimonialGrid language={language} reviews={allReviews} />
              )}

              {hasReviews && post.featuredReviews.length > 1 && (
                <div className="columns">
                  {post.featuredReviews.map(card => (
                    <div key={card.youtube} className="column">
                      <TestimonialPreview
                        language={language}
                        youtube={card.youtube}
                        image={card.image}
                        heading={card.heading}
                        subheading={card.subHeading}
                      />
                    </div>
                  ))}
                </div>
              )}
            </>
          )}

          {/* <LearnMoreCards
            //colorBack
            language={language}
            post={post.learnMoreCards}
          /> */}

          <Section className="mt-3--mobile">
            <Columns sideColumnsSize={1}>
              <div className="column">
                <div className="home-services">
                  <div className="px-40--mobile">
                    <Text as="h2" text={post.learnMoreCards.heading} />
                    <Text
                      className="mt-2 mt-1--mobile"
                      text={post.learnMoreCards.blurb}
                    />
                  </div>
                  {post.learnMoreCards.cards.map((card, idx) => (
                    <div
                      key={card.imageId}
                      className={`${idx >= 1 ? "mt-2--mobile" : ""}`}>
                      <Image publicId={card.imageId} />
                      <Button
                        buttonText={card.button.buttonText}
                        href={card.button.href}
                        contained
                        destination="internal"
                        className="mx-auto--mobile"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Columns>
          </Section>

          <Section className="relative">
            <Image publicId="INLI/DEV/inland-art-of-caring" />
            <Columns sideColumnsSize={2} className="home-about">
              <div className="column px-40--mobile">
                <Text as="h2" text={post.homeSectionOne.heading} />
                <Text useStringReplace as="p" text={post.homeSectionOne.text} />

                <div className="main-homepage__aaahc-content">
                  <Text
                    as="p"
                    text="We are a fully accredited AAAHC surgical center (similar to hospital facilities). This exceeds the standards used in traditional oral and maxillofacial surgery centers by ensuring the highest surgical standards to achieve optimal outcomes."
                  />
                  <div className="main-homepage__aaahc-cta">
                    <div className="cta-logo">
                      <Image
                        className="is-hidden-touch"
                        useAR={false}
                        publicId="INLI/DEV/aaahc-logo-white"
                      />
                      <Image
                        className="is-hidden-desktop"
                        useAR={false}
                        publicId="INLI/DEV/aaahc-logo"
                      />
                    </div>
                    <Button
                      white
                      buttonText="Learn more"
                      href="https://www.aaahc.org/"
                      destination="external"
                      className="mt-0 is-hidden-touch"
                    />
                    <Button
                      contained
                      buttonText="Learn more"
                      href="https://www.aaahc.org/"
                      destination="external"
                      className="mt-0 is-hidden-desktop"
                    />
                  </div>
                </div>
              </div>
            </Columns>
          </Section>

          <Affiliations
            heading="Affiliations:"
            logos={post.affiliations}
            className="is-hidden-desktop"
          />

          <FirstTime
            language={language}
            post={post}
            useImages={post.firstTimePatient.useImages}
            image={post.firstTimePatient.image}
            noReverse
          />

          <Affiliations
            heading="Affiliations:"
            logos={post.affiliations}
            className="is-hidden-touch"
          />

          {/* {post.homeRatingsReviews.hasThisSection && (
            <Section>
              <AllRatingsReviews
                animation
                heading={post.homeRatingsReviews.heading}
                blurb={post.homeRatingsReviews.blurb}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            </Section>
          )} */}
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        toast {
          hasThisSection
          text
          closeDate
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          # blurb
          buttonOne {
            buttonText
            href
          }
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
          useImages
          image
        }
        affiliations {
          logo
          link
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
      }
    }
  }
`

export default IndexPage
